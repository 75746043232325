import { atom } from 'recoil';

import {
  EventBillingTypeEnum,
  TaskFrequencyEnum,
  TaskFrequencyIntervalEnum,
  TaskStatusEnum,
} from '@consts/index';

const eventDrawer = atom<boolean>({
  key: 'eventDrawer',
  default: false,
});

const event = atom<IEvent>({
  key: 'event',
  default: {
    guestArrivesSameDay: false,
    id: 0,
    assignmentId: 0,
    title: '',
    notes: '',
    checklists: [],
    customer: undefined,
    timezone: null,
    start: new Date(),
    end: new Date(),
    location: undefined,
    taskType: 'single-task',
    taskRepeat: {
      frequency: TaskFrequencyIntervalEnum.DAILY,
      interval: 1,
      weeklyDaysRepetition: [],
      monthlyType: TaskFrequencyEnum.BY_DAY_TYPE,
      monthlyEachRepetition: [],
      monthlyOnTheRepetition: ['first', 'day'],
      yearlyMonthRepetition: [],
      yearlyOnTheRepetition: [],
    },
    assignedUsers: [],
    crewAssignmentsNotes: [],
    crewAssignmentsImages: [],
    imagesFile: [],
    images: [],
    status: TaskStatusEnum.TODO,
    assignmentImages: [],
    projectImages: [],
    assignmentNotes: null,
    isRecurring: false,
    recurringProjectRuleId: null,
    completedByExternalClient: 0,
    externalId: null,
    systemNotes: null,
    accountCalendarId: null,
    calendarName: null,
    allDay: false,
    platform: null,
    rate: null,
    billingType: EventBillingTypeEnum.HOURLY,
    deletedAt: null,
  },
});

const selectedEventId = atom<number>({
  key: 'selectedEventId',
  default: 0,
});

const eventStartDate = atom<Date | null>({
  key: 'eventStartDate',
  default: null,
});

const eventStartTime = atom<Date | null>({
  key: 'eventStartTime',
  default: null,
});

const eventEndDate = atom<Date | null>({
  key: 'eventEndDate',
  default: null,
});

const eventEndTime = atom<Date | null>({
  key: 'eventEndTime',
  default: null,
});

export {
  selectedEventId,
  eventStartDate,
  eventStartTime,
  eventEndDate,
  eventEndTime,
  eventDrawer,
  event,
};
