import { DateTime } from 'luxon';
import { ICalendarEvent, ProjectOfferSystemNotes } from 'types/calendar';
import { ProjectOffer } from 'types/projectOffer.type';

import {
  TaskbirdIntegrationsEnum,
  TaskStatusEnum,
  TaskbirdIntegrationsEnumAPI,
  EventBillingTypeEnum,
  TaskFrequencyIntervalEnum,
  TaskFrequencyEnum,
  WeekViewTypesEnum,
} from '@consts/index';
import {
  generateAssignee,
  getTimezoneDateFromISO,
  getEpochToDate,
  userTimezone,
  Camelize,
} from '@helpers/index';

export const getOfferById = (
  offerId: string | number,
  offersData: ProjectOffer[]
): ProjectOffer => {
  let parsedOfferId: number;

  if (typeof offerId === 'string')
    parsedOfferId = Number(offerId.replace('offer-', ''));
  else parsedOfferId = offerId;

  return offersData.find((offer) => offer.id === parsedOfferId);
};

export const filterEvents = (
  events: any[],
  initialDate: number,
  finalDate: number,
  weekViewType: string
) => {
  const filteredEvents: any[] = [];
  const filteredIds = {};

  events.forEach((evt) => {
    const {
      assignments,
      has_assignments,
      end_time_epoch,
      start_time_epoch,
      customer,
    } = evt;

    if (end_time_epoch < initialDate || start_time_epoch > finalDate) {
      return;
    }

    const assigneeIds = has_assignments
      ? assignments.map((assignment) => assignment.user.id)
      : [0];

    if (weekViewType === WeekViewTypesEnum.ASSIGNEE) {
      assigneeIds.forEach((id) => {
        let value = filteredIds[id] ?? 0;
        value++;
        filteredIds[id] = value;
      });
    }

    if (weekViewType === WeekViewTypesEnum.CUSTOMER) {
      const id = customer.id;
      let value = filteredIds[id] ?? 0;
      value++;
      filteredIds[id] = value;
    }

    filteredEvents.push(evt);
  });

  return { filteredEvents, filteredIds };
};

export const handlePlatform = (externalProjectOffers): string => {
  const { platform_id } = externalProjectOffers[0];

  const platforms = {
    1: TaskbirdIntegrationsEnum.MOVEOUT,
    2: TaskbirdIntegrationsEnum.TURNOVERBNB,
    3: TaskbirdIntegrationsEnum.GOOGLE,
    4: TaskbirdIntegrationsEnum.BOOKING_WIDGET,
  };

  return platforms[platform_id];
};

const handleStatus = (
  status: string,
  assignments: any[],
  isOffer: boolean
): string => {
  if ((status === TaskStatusEnum.TODO && assignments.length === 0) || isOffer) {
    return TaskStatusEnum.UNASSIGNED;
  }

  return status;
};

const formatEvent = (result, isEventOffer = false, weekViewType) => {
  const {
    id,
    name,
    status,
    start_time_epoch,
    end_time_epoch,
    started_at,
    finished_at,
    location,
    customer,
    assignments,
    external_id,
    is_recurring,
    uid,
    account_calendar_id,
    price,
    current_status,
    has_assignments,
    external_project_offers,
    project_offer_payment_type,
    travel_distance,
    travel_distance_unit,
    project_offer_system_notes,
    same_day_arrival,
    original_start_time_epoch,
    original_end_time_epoch,
  } = result;

  const assigneeNames = has_assignments
    ? assignments.map((assignment) => assignment.user.name)
    : [];

  const assigneeIds = has_assignments
    ? assignments.map((assignment) => assignment.user.id)
    : [0];

  const startDate = start_time_epoch
    ? DateTime.fromSeconds(start_time_epoch).setZone(
        location?.timezone ?? userTimezone()
      )
    : DateTime.invalid('Null date');

  const endDate = end_time_epoch
    ? DateTime.fromSeconds(end_time_epoch).setZone(
        location?.timezone ?? userTimezone()
      )
    : DateTime.invalid('Null date');

  const startAt = started_at
    ? DateTime.fromSeconds(started_at).setZone(
        location?.timezone ?? userTimezone()
      )
    : startDate;

  const endAt = finished_at
    ? DateTime.fromSeconds(finished_at).setZone(
        location?.timezone ?? userTimezone()
      )
    : DateTime.invalid('Null date');

  const event: ICalendarEvent = {
    id: isEventOffer ? `offer-${id}` : id,
    title: name,
    start: startDate.toJSDate(),
    end: endDate.isValid ? endDate.plus({ day: 1 }).toJSDate() : null,
    allDay: true,
    resourceIds: isEventOffer
      ? [0]
      : weekViewType === WeekViewTypesEnum.CUSTOMER
      ? [customer.id]
      : assigneeIds,
    extendedProps: {
      id: isEventOffer ? `offer-${id}` : id,
      status: handleStatus(status, assignments, isEventOffer),
      assigneeNames,
      end_time_epoch,
      start_time_epoch,
      location,
      address: handleLocationAddress(location),
      currentStatus: current_status,
      accountCalendarId: account_calendar_id,
      end_time:
        isEventOffer && end_time_epoch
          ? endDate
          : !isEventOffer && status === TaskStatusEnum.DONE
          ? endAt
          : endDate,
      start_time: status === TaskStatusEnum.DONE ? startAt : startDate,
      isOffer: isEventOffer,
      externalProjectOffers: external_project_offers,
      platform: isEventOffer ? handlePlatform(external_project_offers) : null,
      customer,
      externalId: external_id,
      projectOfferPaymentType: project_offer_payment_type,
      assigneeIds,
      isRecurring: is_recurring === 1,
      sameDayArrival: Boolean(same_day_arrival),
      price,
      uid,
      travelDistance: isEventOffer ? travel_distance : 0,
      travelDistanceUnit: isEventOffer ? travel_distance_unit : 'mi',
      projectOfferSystemNotes:
        Camelize<ProjectOfferSystemNotes>(project_offer_system_notes) ?? null,
      original_start_time_epoch,
      original_end_time_epoch,
    },
  };

  return event;
};

export const getFormattedEvents = (data, offers, weekViewType) => {
  const events = data.map((result) => formatEvent(result, false, weekViewType));

  const eventOffers = offers.map((offer) =>
    formatEvent(offer, true, weekViewType)
  );

  const finalEvents = events.concat(eventOffers);

  return finalEvents;
};

export const isSameResource = (
  newResource: string | undefined,
  oldResource: string | undefined
): boolean => {
  if (verifyResources(newResource, oldResource)) {
    if (newResource !== oldResource) {
      return false;
    }
    return true;
  }

  if (newResource === undefined && oldResource === undefined) {
    return true;
  }

  return false;
};

export const verifyResources = (
  newResource: string | undefined,
  oldResource: string | undefined
): boolean => newResource !== undefined && oldResource !== undefined;

export const handleUsersList = (users, oldResource, newResource) => {
  const newUsers = users.filter((i) => i !== Number(oldResource));
  if (Number(newResource) !== 0) newUsers.push(Number(newResource));
  return newUsers;
};

const handleEventPlatform = (platform) => {
  if (!platform) {
    return null;
  }

  switch (platform.toLowerCase()) {
    case TaskbirdIntegrationsEnumAPI.GOOGLE:
      return TaskbirdIntegrationsEnum.GOOGLE;

    case TaskbirdIntegrationsEnumAPI.TURNOVERBNB:
      return TaskbirdIntegrationsEnum.TURNOVERBNB;

    case TaskbirdIntegrationsEnumAPI.MOVEOUT:
      return TaskbirdIntegrationsEnum.MOVEOUT;

    case TaskbirdIntegrationsEnumAPI.BOOKING_WIDGET:
      return TaskbirdIntegrationsEnum.BOOKING_WIDGET;

    default:
      return null;
  }
};

export const getFormattedEvent = (evt: any): IEvent => {
  const handleAssigneeUsers = (assignees: any[]) =>
    assignees.map((i) => generateAssignee(i, true));

  const start = getTimezoneDateFromISO(evt.startTime, evt.timezone).toJSDate();
  const end = getTimezoneDateFromISO(evt.endTime, evt.timezone).toJSDate();

  const nEvent: IEvent = {
    guestArrivesSameDay: false,
    id: evt.id,
    assignmentId: evt.assignmentId,
    title: evt.title,
    start: start,
    end: end,
    startedAt: evt.startedAt
      ? getTimezoneDateFromISO(evt.startedAt, evt.timezone).toJSDate()
      : start,
    finishedAt: evt.finishedAt
      ? getTimezoneDateFromISO(evt.finishedAt, evt.timezone).toJSDate()
      : end,
    status: evt.status,
    timezone: evt.timezone,
    isRecurring: evt.is_recurring === 1,
    recurringProjectRuleId: evt.recurring_project_rule_id,
    customerId: evt.customer_id,
    customerName: evt.customerName,
    customerPhone: evt.customerPhone,
    customerEmail: evt.customerEmail,
    notes: evt.notes === null ? '' : evt.notes,
    images: evt.images,
    assignmentImages: evt.assignmentImages,
    assignmentStatus: evt.assignmentStatus,
    assignmentNotes: evt.assignmentNotes,
    assignedUsers: handleAssigneeUsers(evt.assignedUsers),
    checklists: evt.checklists,
    externalId: evt.externalId,
    systemNotes: evt.systemNotes,
    imagesFile: [],
    projectImages: [],
    taskType: '',
    taskRepeat: {
      frequency: TaskFrequencyIntervalEnum.DAILY,
      interval: 1,
      weeklyDaysRepetition: [],
      monthlyType: TaskFrequencyEnum.BY_DAY_TYPE,
      monthlyEachRepetition: [],
      monthlyOnTheRepetition: ['first', 'day'],
      yearlyMonthRepetition: [],
      yearlyOnTheRepetition: [],
    },
    completedByExternalClient: evt.completedByExternalClient,
    crewAssignmentsNotes: evt.crewAssignmentsNotes,
    crewAssignmentsImages: evt.crewAssignmentsImages,
    accountCalendarId: evt.accountCalendarId,
    calendarName: evt.calendarName,
    allDay: evt.allDay,
    platform: handleEventPlatform(evt.platform),
    rate: evt.rate,
    billingType: evt.billingType ?? EventBillingTypeEnum.HOURLY,
    deletedAt: evt.deletedAt ?? null,
    inventory: evt.inventory ?? null,
  };

  if (evt.customerId) {
    nEvent.customer = {
      id: evt.customerId,
      name: evt.customerName,
      locations: [],
      phone_number: evt.customerPhone,
    };
  }

  if (evt.locationId) {
    nEvent.location = evt.location;
  }

  return nEvent;
};

export const getFormattedResources = (data, filteredIds) => {
  return data.map(({ id, name, count, photo_url }) => {
    const finalCount = count - (count - filteredIds[id]);

    return {
      id,
      order: id === 0 ? 0 : 1,
      title: name,
      extendedProps: {
        photo_url,
        count: isNaN(finalCount) ? 0 : finalCount,
      },
    };
  });
};

const handleLocationAddress = (location: ILocationTask) => {
  if (location.streetAddress) {
    return location.streetAddress;
  }

  if (location.street_address) {
    return location.street_address;
  }

  return '';
};

export const getFormattedOffer = (evt: any): ProjectOffer => {
  const offer: ProjectOffer = {
    guestArrivesSameDay: Boolean(evt.guestArrivesSameDay),
    id: evt.id,
    title: evt.name,
    platform: handlePlatform(
      evt.external_project_offers
    ) as TaskbirdIntegrationsEnum,
    assignmentId: 0,
    externalProjectOffers: evt.external_project_offers,
    jobDuration: evt.job_duration,
    price: evt.price,
    currentStatus: evt.current_status,
    start: getEpochToDate(evt.start_time_epoch),
    end: getEpochToDate(evt.end_time_epoch),
    startedAt: null,
    finishedAt: null,
    status: '',
    timezone: null,
    isRecurring: false,
    recurringProjectRuleId: null,
    customerId: evt.customer.id,
    customerName: evt.customer.name,
    customerPhone: evt.customer.phone_number,
    notes: evt.notes ?? '',
    images: evt.images,
    assignmentImages: [],
    assignmentStatus: null,
    assignmentNotes: null,
    assignedUsers: undefined,
    completedByExternalClient: evt.completedByExternalClient,
    checklists: evt.checklists,
    externalId: evt.externalId,
    systemNotes: null,
    projectOfferSystemNotes:
      Camelize<ProjectOfferSystemNotes>(evt.project_offer_system_notes) ?? null,
    imagesFile: [],
    projectImages: [],
    taskType: '',
    taskRepeat: {
      frequency: TaskFrequencyIntervalEnum.DAILY,
      interval: 1,
      weeklyDaysRepetition: [],
      monthlyType: TaskFrequencyEnum.BY_DAY_TYPE,
      monthlyEachRepetition: [],
      monthlyOnTheRepetition: ['first', 'day'],
      yearlyMonthRepetition: [],
      yearlyOnTheRepetition: [],
    },

    crewAssignmentsNotes: [],
    crewAssignmentsImages: [],
    projectOfferPaymentType: evt.project_offer_payment_type,
    accountCalendarId: null,
    calendarName: null,
    allDay: evt.allDay ?? false,
    rate: evt.rate,
    client: evt.client ?? '',
    billingType: evt.billingType ?? EventBillingTypeEnum.HOURLY,
    travelDistance: evt.travel_distance ?? 0,
    travelDistanceUnit: evt.travel_distance_unit ?? 'mi',
    deletedAt: evt.deletedAt ?? null,
  };

  if (evt.customer_id) {
    offer.customer = evt.customer;
  }

  if (evt.location_id) {
    offer.location = evt.location;
  }

  return offer;
};

export const isFirstDaySunday = (
  firstDay: string | undefined,
  isMonthView = false
): 0 | 1 => {
  if (!isMonthView && firstDay === 'sunday') {
    return 1;
  }
  return 0;
};
