import React from 'react';

import { Grid } from '@mui/material';

import { TkTags } from '@components/index';
import { TaskStatusEnum, TaskbirdIntegrationsEnum } from '@consts/index';

interface ITkDrawerTagsList {
  event: IEvent;
}

const isIntegratedWithTBNB = (externalId: string | null): boolean =>
  externalId !== null;

const isIntegratedWithExternalCalendar = (
  accountCalendarId: number | null
): boolean => accountCalendarId !== null;

const TkDrawerTagsList = ({ event }: ITkDrawerTagsList): JSX.Element => {
  const hasGoogleIntegration = isIntegratedWithExternalCalendar(
    event.accountCalendarId
  );

  const hasTBNBIntegration = isIntegratedWithTBNB(event.externalId);

  const guestArrivesSameDay = event.systemNotes?.guestArrivesSameDay;

  const { isRecurring, status, completedByExternalClient } = event;

  return (
    <Grid container direction="row" justifyContent="center">
      {hasGoogleIntegration && (
        <TkTags type="googleCalendar" calendarName={event.calendarName ?? ''} />
      )}

      {event.platform &&
        event.platform !== TaskbirdIntegrationsEnum.TURNOVERBNB &&
        !hasGoogleIntegration && <TkTags type={event.platform} />}

      {hasTBNBIntegration && (
        <TkTags type={TaskbirdIntegrationsEnum.TURNOVERBNB} />
      )}

      {isRecurring && <TkTags type="repeat" />}

      {status === TaskStatusEnum.DONE && (
        <TkTags
          type={
            hasTBNBIntegration && completedByExternalClient === 1
              ? 'completedTBnB'
              : 'completed'
          }
        />
      )}

      {guestArrivesSameDay && <TkTags type="guestArrivesSameDay" />}
    </Grid>
  );
};

export default TkDrawerTagsList;
