import { atom } from 'recoil';

import {
  EventBillingTypeEnum,
  TaskFrequencyIntervalEnum,
  TaskStatusEnum,
  TaskFrequencyEnum,
} from '@consts/index';

const editEvent = atom<IEvent>({
  key: 'editEvent',
  default: {
    id: 0,
    assignmentId: 0,
    title: '',
    notes: '',
    checklists: [],
    customer: undefined,
    timezone: null,
    start: new Date(),
    end: new Date(),
    location: undefined,
    taskType: 'single-task',
    taskRepeat: {
      frequency: TaskFrequencyIntervalEnum.DAILY,
      interval: 1,
      weeklyDaysRepetition: [],
      monthlyType: TaskFrequencyEnum.BY_DAY_TYPE,
      monthlyEachRepetition: [],
      monthlyOnTheRepetition: ['first', 'day'],
      yearlyMonthRepetition: [],
      yearlyOnTheRepetition: [],
    },
    assignedUsers: [],
    crewAssignmentsNotes: [],
    crewAssignmentsImages: [],
    imagesFile: [],
    images: [],
    status: TaskStatusEnum.TODO,
    assignmentImages: [],
    projectImages: [],
    assignmentNotes: null,
    isRecurring: false,
    recurringProjectRuleId: null,
    completedByExternalClient: 0,
    externalId: null,
    systemNotes: null,
    accountCalendarId: null,
    calendarName: null,
    allDay: false,
    platform: null,
    rate: null,
    billingType: EventBillingTypeEnum.HOURLY,
    guestArrivesSameDay: false,
    deletedAt: null,
    inventory: null,
  },
});

const editEventExtendedDrawer = atom<ITkExtendedObject>({
  key: 'editEventExtendedDrawer',
  default: {
    open: false,
    type: '',
  },
});

const isEventEdit = atom<boolean>({
  key: 'isEventEdit',
  default: false,
});

const isEventDeleted = atom<boolean>({
  key: 'isEventDeleted',
  default: false,
});

const editEventSelectedChecklist = atom<IChecklistTask | undefined>({
  key: 'editEventSelectedChecklist',
  default: undefined,
});

const editEventTeammatesImageCount = atom<number>({
  key: 'editEventTeammatesImageCount',
  default: 0,
});

export {
  editEventTeammatesImageCount,
  editEventSelectedChecklist,
  editEventExtendedDrawer,
  isEventDeleted,
  isEventEdit,
  editEvent,
};
